import React, { useContext }  from 'react';
import { Link }               from 'gatsby';
import { IconButton, VStack } from '@chakra-ui/react';
import { TbBasket }           from 'react-icons/tb';

import { StoreContext } from '@interness/ecommerce-addon/src/context/Store';

const CartIcon = ({ buttonStyle }) => {
  const { cart, storeRootPath, settings } = useContext(StoreContext);

  if (!storeRootPath) {
    return null;
  }

  if (settings.data && settings.data.enable_cart) {
    return (
      <VStack sx={{
        p: {
          marginTop: '0 !important'
        }
      }}>
        <IconButton
          as={Link}
          to={`${storeRootPath}/warenkorb`}
          size="lg"
          {...buttonStyle}
          aria-label={`Warenkorb (${cart.totalCount})`}
          icon={<TbBasket/>}/>
        <p>Warenkorb</p>
      </VStack>
    )
  } else {
    return null;
  }
}

export default CartIcon;